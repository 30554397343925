exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-index-js": () => import("./../../../src/pages/learn/index.js" /* webpackChunkName: "component---src-pages-learn-index-js" */),
  "component---src-pages-learn-mdx-frontmatter-slug-js-content-file-path-home-colby-programs-denicode-llc-src-posts-benefits-of-digital-media-agencies-mdx": () => import("./../../../src/pages/learn/{mdx.frontmatter__slug}.js?__contentFilePath=/home/colby/Programs/denicodeLLC/src/posts/benefits-of-digital-media-agencies.mdx" /* webpackChunkName: "component---src-pages-learn-mdx-frontmatter-slug-js-content-file-path-home-colby-programs-denicode-llc-src-posts-benefits-of-digital-media-agencies-mdx" */),
  "component---src-pages-learn-mdx-frontmatter-slug-js-content-file-path-home-colby-programs-denicode-llc-src-posts-importance-of-seo-mdx": () => import("./../../../src/pages/learn/{mdx.frontmatter__slug}.js?__contentFilePath=/home/colby/Programs/denicodeLLC/src/posts/importance-of-seo.mdx" /* webpackChunkName: "component---src-pages-learn-mdx-frontmatter-slug-js-content-file-path-home-colby-programs-denicode-llc-src-posts-importance-of-seo-mdx" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-start-js": () => import("./../../../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */)
}

